<template>
  <div class="home">
    <vue-particles color="34f834" class="particles"></vue-particles>
    <!-- Banner -->
    <div id="banner">
      <div class="bg-image py-2 text-center text-white">
        <div class="container-fluid">
          <div class="banner-content">
            <div class="mt-1">
              <img
                data-aos="fade-down"
                class="img-fluid animate-logo"
                src="~@/assets/images/Header/Artboard-9.png"
                alt=""
                width="350px"
                height="300px"
              />
            </div>
            <div class="d-flex justify-content-center banner-content2" data-aos="fade-up">
              <vue-typed-js :strings="['BUILD YOUR OWN WORLD']" :loop="false" :cursorChar="'_'">
                <h1 class="typing"></h1>
              </vue-typed-js>
            </div>
            <div class="row justify-content-center">
              <div class="banner-btn col-sm-6 col-md-4 col-lg-4 col-xl-3 mt-4">
                <img
                  data-aos="fade-right"
                  class="animate-btn ml-custom-button-banner"
                  src="~@/assets/images/Header/Artboard 59.png"
                  alt=""
                  width="260px"
                  height="80px"
                />
              </div>
              <div class="banner-btn col-sm-6 col-md-4 col-lg-4 col-xl-3 mt-4">
                <img
                  data-aos="fade-left"
                  class="animate-btn mr-custom-button-banner"
                  src="~@/assets/images/Header/Artboard-60.png"
                  alt=""
                  width="260px"
                  height="80px"
                />
              </div>
            </div>
            <div class="d-flex justify-content-center mt-4" data-aos="fade-up">
              <p class="trailer-btn" v-b-modal.modal-center @click="loadVideo()">TRAILER</p>
            </div>
            <div class="mt-4 footer-banner">
              <div class="item">
                <div class="item-number">
                  <ICountUp class="" :delay="delay" :endVal="167000" :options="options2" />
                </div>
                <div class="item-text">FIXED NFT SUPPLY</div>
              </div>
              <div class="item">
                <div class="item-number">
                  <ICountUp class="" :delay="delay" :endVal="55000" :options="options2" />
                </div>
                <div class="item-text">NFT FOR SALE</div>
              </div>
              <div class="item">
                <div class="item-number">
                  <ICountUp class="" :delay="delay" :endVal="45000" :options="options2" />
                </div>
                <div class="item-text">MARS LAND NFT</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- introduce -->
    <div>
      <div class="bg-image shadow-1-strong text-white padding-custom" id="about">
        <div class="container my-5">
          <div class="row align-items-center mx-auto">
            <div class="col-md-12 col-lg-6 p-0" data-aos="fade-right">
              <p class="introduction-content">
                Heroland is a 1:1 virtual version of Mars divided into 16,700,000 land plots.
                However, the player can only own a third of the purchased plots and the remaining
                plots will be released in later stages through Punitive Expedition Mode. <br />
                Ownership and exchange of plots in Heroland is completely allowed. Then, use NFT
                Hero to build and exploit buildings to develop income when owning land.<br />
                Become a real estate tycoon in the world of HeroLand. Sell them or explore the
                potential hidden under the soil layers? The decision is up to you!
              </p>
            </div>
            <div class="text-center col-md-12 col-lg-6 p-0" data-aos="fade-left">
              <img
                data-aos="fade-up"
                class="img-fluid animate-btn"
                src="~@/assets/images/Artboard-35.jpg"
                alt=""
                width="450px"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Content -->
    <div id="content">
      <!-- Heroland base -->
      <div class="bg-image-heroland-content shadow-1-strong text-white padding-custom" id="base">
        <div class="container-fluid p-0">
          <!-- Herobase -->
          <div class="heroland-base" data-aos="fade-up">
            <div class="text-center text-heading bg-content-typing">
              <vue-typed-js :strings="['HEROLAND BASE']" :loop="true" :cursorChar="'_'">
                <h1 class="typing"></h1>
              </vue-typed-js>
            </div>
            <div class="heroland-base-carousel">
              <Carousel
                :autoplay="true"
                :loop="true"
                :perPageCustom="[
                  [320, 1],
                  [575, 2],
                  [767, 2],
                  [991, 3],
                  [1200, 4],
                  [1600, 4],
                  [2200, 4],
                  [3200, 4],
                ]"
                :paginationEnabled="false"
                :navigationEnabled="true"
                :navigation-next-label="navigationNext"
                :navigation-prev-label="navigationPrev"
              >
                <Slide v-for="(item, i) in base" :key="i">
                  <div class="box-img" @click="onChooseBase(i)">
                    <img
                      :src="require(`@/assets/images/base/${item.img}.png`)"
                      alt=""
                      width="80%"
                      height="auto"
                    />
                  </div>
                </Slide>
              </Carousel>
            </div>
          </div>
          <!-- Electric base -->
          <div class="container electric-base">
            <div class="row align-items-center justify-content-center mx-auto py-4 mt-2">
              <div
                class="text-center text-sm-center col-xl-6 col-lg-6 col-md-12 mb-3 px-0"
                data-aos="fade-right"
              >
                <img
                  :src="require(`@/assets/images/base/${itemBase.img}.png`)"
                  alt=""
                  class="img-fluid img-electric-base animate-btn"
                />
              </div>
              <div
                class="text-lg-start text-md-center text-center col-xl-6 col-lg-6 col-md-12 mt-md-3 mt-lg-0 px-0"
                data-aos="fade-left"
              >
                <div class="base-title">{{ itemBase.title }}</div>
                <p class="lh-md text-md-start electric-base-content" v-html="itemBase.content"></p>
              </div>
            </div>
          </div>
        </div>
        <div
          class="text-center text-sm-center text-heading-landownerbenefit bg-content-typing"
          id="benefit"
        >
          <vue-typed-js :strings="['LANDOWNER BENEFITS']" :loop="true" :cursorChar="'_'">
            <h1 class="typing"></h1>
          </vue-typed-js>
        </div>
      </div>
      <!-- landownerbenefit -->
      <div class="bg-image-heroland-content2 shadow-1-strong text-white">
        <div class="bg-color">
          <div class="landownerbenefit-content" data-aos="fade-up">
            <Carousel :perPage="1" :paginationEnabled="true" :navigationEnabled="false">
              <Slide v-for="(item, i) in landownerbenefits" :key="i">
                <div class="landownerbenefit-item">
                  <div class="landownerbenefit-img">
                    <img :src="require(`@/assets/images/icons-benefits/${item.img}.png`)" alt="" />
                  </div>
                  <div class="landownerbenefit-text">
                    {{ item.content }}
                  </div>
                </div>
              </Slide>
            </Carousel>
          </div>
          <div class="text-center mt-5 padding-custom" id="token">
            <img
              data-aos="fade-up"
              src="~@/assets/images/Artboard 38.png"
              alt=""
              class="img-fluid mb-3"
              style="width: 600px"
            />
            <p class="mb-5" data-aos="fade-up">
              Hero Book's huge ecosystem needs a factor that links all of<br />
              them that's when the HBG Token appears and connects everything.
            </p>
          </div>
          <div class="text-center mt-5 position-relative">
            <img
              data-aos="fade-up"
              src="~@/assets/images/Artboard 97.png"
              alt=""
              class="img-fluid mb-3"
              style="width: 500px"
            />
            <p data-aos="fade-up">
              Buy Hero Boxes or Land to own your NFT<br />
              Buy, sell, exchange nft in the ecosystem <br />
              Build your own Blockchain Game on the HeroBook system with our support.
            </p>
          </div>
          <div class="text-center button-group mt-5 position-relative" data-aos="fade-up">
            <a
              href="https://bscscan.com/token/0x8c2Da84EA88151109478846cc7c6c06C481dbe97"
              target="_blank"
            >
              <img src="~@/assets/images/button/ct.png" alt="" class="img-fluid mb-3 animate-btn" />
            </a>
            <a href="./pdf/REP-HeroBook-2022-01-17.pdf?v=pi" target="_blank">
              <img
                src="~@/assets/images/button/audit.png"
                alt=""
                class="img-fluid mb-3 animate-btn"
              />
            </a>
            <a
              href="https://www.team.finance/view-coin/0x8c2Da84EA88151109478846cc7c6c06C481dbe97?name=Hero%20Book%20Game%20Token&symbol=HBG"
              target="_blank"
            >
              <img
                src="~@/assets/images/button/lock.png"
                alt=""
                class="img-fluid mb-3 animate-btn"
              />
            </a>
            <a href="https://marketplace.herobook.io/pre-sale" target="_blank">
              <img
                src="~@/assets/images/button/buy.png"
                alt=""
                class="img-fluid mb-3 animate-btn"
              />
            </a>
            <a href="https://herobook.io/token-metric" target="_blank">
              <img src="~@/assets/images/button/dt.png" alt="" class="img-fluid mb-3 animate-btn" />
            </a>
          </div>
        </div>
        <div class="container mt-5 position-relative">
          <img
            data-aos="fade-up"
            src="~@/assets/images/Artboard 76.png"
            alt=""
            class="img-fluid"
            style="width: 600px"
          />
        </div>
        <div class="bg-chart">
          <div class="container py-2 position-relative">
            <div
              class="row mx-auto justify-content-lg-center justify-content-md-center justify-content-sm-center align-items-center mt-2"
            >
              <div class="col-lg-7 col-xs-12 col-sm-9" data-aos="fade-right">
                <div class="d-flex align-items-center">
                  <div class="percent percent1 fw-bold">
                    <h2><ICountUp class="" :delay="delay2" :endVal="3" :options="options2" />%</h2>
                  </div>
                  <div class="text ms-5">
                    <p>
                      on Airdrop campaign and marketing promotion - <br />
                      <strong
                        ><ICountUp class="" :delay="delay2" :endVal="30000000" :options="options3"
                      /></strong>
                    </p>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="percent percent2 fw-bold presaletext">
                    <h2><ICountUp class="" :delay="delay2" :endVal="15" :options="options2" />%</h2>
                  </div>
                  <div class="text ms-4">
                    <p>
                      Pre-Sale Release -
                      <strong
                        ><ICountUp class="" :delay="delay2" :endVal="150000000" :options="options3"
                      /></strong>
                    </p>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="percent percent3 fw-bold advisorytext">
                    <h2><ICountUp class="" :delay="delay2" :endVal="5" :options="options2" />%</h2>
                  </div>
                  <div class="text ms-5">
                    <p>
                      under the management of the Advisory Board - <br />
                      <strong
                        ><ICountUp class="" :delay="delay2" :endVal="50000000" :options="options3"
                      /></strong>
                    </p>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="percent percent4 fw-bold reservetext">
                    <h2><ICountUp class="" :delay="delay2" :endVal="15" :options="options2" />%</h2>
                  </div>
                  <div class="text ms-4">
                    <p>
                      listed on reserve fund and exchange -
                      <strong
                        ><ICountUp class="" :delay="delay2" :endVal="150000000" :options="options3"
                      /></strong>
                    </p>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="percent percent5 fw-bold p2etext">
                    <h2><ICountUp class="" :delay="delay2" :endVal="25" :options="options2" />%</h2>
                  </div>
                  <div class="text ms-4">
                    <p>
                      for P2E - accompanying ecosystem -
                      <strong
                        ><ICountUp class="" :delay="delay2" :endVal="250000000" :options="options3"
                      /></strong>
                    </p>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="percent percent6 fw-bold admintext">
                    <h2><ICountUp class="" :delay="delay2" :endVal="15" :options="options2" />%</h2>
                  </div>
                  <div class="text ms-4">
                    <p>
                      under the management of the development team and ecosystem administrator -
                      <strong
                        ><ICountUp class="" :delay="delay2" :endVal="150000000" :options="options3"
                      /></strong>
                    </p>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="percent percent7 fw-bold marketingtext">
                    <h2><ICountUp class="" :delay="delay2" :endVal="12" :options="options2" />%</h2>
                  </div>
                  <div class="text ms-4">
                    <p>
                      for international marketing and expansion -
                      <strong
                        ><ICountUp class="" :delay="delay2" :endVal="120000000" :options="options3"
                      /></strong>
                    </p>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="percent percent8 fw-bold developtext">
                    <h2><ICountUp class="" :delay="delay2" :endVal="10" :options="options2" />%</h2>
                  </div>
                  <div class="text ms-4">
                    <p>
                      for project development fund -
                      <strong
                        ><ICountUp class="" :delay="delay2" :endVal="100000000" :options="options3"
                      /></strong>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-5 col-xs-12 col-sm-9 text-center" data-aos="fade-left">
                <apexchart
                  type="pie"
                  width="100%"
                  :options="chartOptions"
                  :series="series"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-image-heroland-content3 shadow-1-stong text-white" id="roadmap">
        <div class="bg-roadmap">
          <div class="container-fluid py-2">
            <div class="text-center mb-5 position-relative">
              <img
                data-aos="fade-up"
                src="~@/assets/images/Artboard 75.png"
                alt="roadmap"
                class="img-fluid"
                style="width: 250px; height: 80px"
              />
            </div>
            <div v-if="widthRs > 767" class="w-100 text-center position-relative">
              <img
                data-aos="fade-up"
                src="~@/assets/images/roadmap/Artboard 29.png"
                alt=""
                class="img-fluid"
                width="1300px"
              />
            </div>
            <div
              v-else
              class="roadmap-mobile d-flex align-items-center flex-wrap justify-content-center position-relative"
            >
              <img
                data-aos="fade-up"
                src="~@/assets/images/roadmap/p-1.png"
                alt=""
                class="img-fluid mb-3 mx-2"
                width="200px"
              />
              <img
                data-aos="fade-up"
                src="~@/assets/images/roadmap/p-2.png"
                alt=""
                class="img-fluid mb-3 mx-2"
                width="200px"
              />
              <img
                data-aos="fade-up"
                src="~@/assets/images/roadmap/p-3.png"
                alt=""
                class="img-fluid mb-3 mx-2"
                width="200px"
              />
              <img
                data-aos="fade-up"
                src="~@/assets/images/roadmap/p-4.png"
                alt=""
                class="img-fluid mb-3 mx-2"
                width="200px"
              />
              <img
                data-aos="fade-up"
                src="~@/assets/images/roadmap/p-5.png"
                alt=""
                class="img-fluid"
                width="200px"
              />
            </div>
          </div>
        </div>
        <div class="text-center padding-custom position-relative" id="partner">
          <div class="bg-content-typing">
            <h1>OUR BACKER</h1>
          </div>
        </div>
      </div>
      <div class="bg-image-heroland-content4">
        <div class="container position-relative">
          <div class="row justify-content-md-center text-center justify-content-sm-center mx-auto">
            <div
              class="col-lg-2 col-md-4 col-sm-4 col-6 mb-3 partner"
              data-aos="fade-up"
              v-for="(partner, idx) in backers"
              :key="idx"
            >
              <a href="#" target="_blank">
                <img :src="require(`@/assets/images/backer/${idx + 1}.png`)" alt="" />
              </a>
            </div>
          </div>
        </div>
        <div class="text-center ourpartner position-relative">
          <div class="bg-content-typing">
            <h1>OUR PARTNER</h1>
          </div>
        </div>
        <div class="container position-relative">
          <div
            class="mt-5 row justify-content-md-center text-center justify-content-sm-center mx-auto mb-5"
          >
            <div
              class="col-lg-2 col-md-4 col-sm-4 col-6 mb-3 partner"
              data-aos="fade-up"
              v-for="(partner, idx) in ourPartners"
              :key="idx"
            >
              <a :href="partner.link" target="_blank">
                <img :src="require(`@/assets/images/partner/${idx + 1}.png`)" alt="" />
              </a>
            </div>
          </div>
        </div>
        <div class="text-center ourpartner position-relative">
          <div class="bg-content-typing">
            <h1>MEDIA PARTNER</h1>
          </div>
        </div>
        <div class="container position-relative">
          <div
            class="mt-5 row justify-content-md-center text-center justify-content-sm-center mx-auto mb-5"
          >
            <div
              class="col-lg-2 col-md-4 col-sm-4 col-6 mb-3 partner"
              data-aos="fade-up"
              v-for="(media, idx) in mediaPartners"
              :key="idx"
            >
              <a :href="media.link" target="_blank">
                <img :src="require(`@/assets/images/media-partner/${idx + 1}.png`)" alt="" />
              </a>
            </div>
          </div>
        </div>
        <div class="text-center ourpartner position-relative">
          <div class="bg-content-typing">
            <h1>ON VIETNAM NEWSPAPERS</h1>
          </div>
        </div>
        <div class="container position-relative">
          <div
            class="mt-5 row justify-content-md-center text-center justify-content-sm-center mx-auto mb-5"
          >
            <div
              class="col-lg-2 col-md-4 col-sm-4 col-6 mb-3 partner"
              data-aos="fade-up"
              v-for="(paper, idx) in newsPapers"
              :key="idx"
            >
              <a :href="paper.link" target="_blank">
                <img :src="require(`@/assets/images/newspaper/${idx + 1}.png`)" alt="" />
              </a>
            </div>
          </div>
        </div>
        <div class="bg-color build padding-custom">
          <div class="text-center position-relative">
            <img
              data-aos="fade-up"
              src="~@/assets/images/Artboard 58.png"
              alt="build"
              class="img-fluid"
              style="width: 600px; height: auto"
            />
          </div>
          <div class="text-center mt-5 position-relative" id="community">
            <img
              data-aos="fade-up"
              class="animate-btn img-fluid"
              src="~@/assets/images/Header/Artboard 59.png"
              alt="getland-btn"
              style="width: 260px"
            />
          </div>
        </div>
        <div class="bg py-5">
          <div class="container position-relative">
            <div class="row mx-auto">
              <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" data-aos="fade-right">
                <div class="contact-content"><h5>GET IN TOUCH</h5></div>
                <div class="contact-content">
                  <h3>JOIN OUR COMMUNITY</h3>
                </div>
                <div class="d-flex flex-wrap mx-auto mt-4 text-center link">
                  <a href="https://twitter.com/HeroBookGlobal" target="_blank">
                    <img
                      src="~@/assets/images/Artboard 66.png"
                      alt="twitter"
                      class="img-fluid"
                      style="width: 50px; height: 50px"
                    />
                    <p>Twitter</p>
                  </a>
                  <a class="ms-3" href="https://www.facebook.com/herobookglobal" target="_blank"
                    ><img
                      src="~@/assets/images/Artboard 66_1.png"
                      alt="facebook"
                      class="img-fluid"
                      style="width: 50px; height: 50px"
                    />
                    <p>Facebook</p></a
                  >
                  <a class="ms-3" href="https://t.me/herobook_chat" target="_blank"
                    ><img
                      src="~@/assets/images/Artboard 67.png"
                      alt="telegram"
                      class="img-fluid"
                      style="width: 50px; height: 50px"
                    />
                    <p>Telegram</p></a
                  >
                  <a class="ms-3" href="https://t.me/herobook_ann" target="_blank"
                    ><img
                      src="~@/assets/images/Artboard 67.png"
                      alt="telegram"
                      class="img-fluid"
                      style="width: 50px; height: 50px"
                    />
                    <p>Telegram</p></a
                  >
                  <a class="ms-3" href="mailto:support@herobook.io">
                    <img
                      src="~@/assets/images/Artboard 68.png"
                      alt="email"
                      class="img-fluid"
                      style="width: 50px; height: 50px"
                    />
                    <p>Email</p>
                  </a>
                  <a class="ms-3" href="#"
                    ><img
                      src="~@/assets/images/Artboard 69.png"
                      alt="web"
                      class="img-fluid"
                      style="width: 50px; height: 50px"
                    />
                    <p>Website</p>
                  </a>
                </div>
                <div><h5>Contact us: Support@herobook.io</h5></div>
              </div>
              <div class="col-lg-6 col-md-4 col-sm-12 col-xs-12" data-aos="fade-left">
                <img src="~@/assets/images/Artboard 73.png" alt="" class="img-fluid animate-logo" />
              </div>
            </div>
          </div>
        </div>
        <!-- Footer -->
        <footer class="text-center text-lg-start text-white bg-color py-5">
          <section>
            <div class="container text-center text-md-start position-relative">
              <div class="row mt-3">
                <div class="col-md-3 col-lg-4 col-xl-3 mx-auto mb-4">
                  <img
                    src="~@/assets/images/Artboard 92.png"
                    alt="herobook"
                    class="img-fluid"
                    style="width: 200px"
                  />
                </div>

                <div class="col-md-2 col-lg-2 col-xl-2 mx-auto mb-4">
                  <h4 class="text-uppercase fw-bold mb-4 text-footer">ABOUT</h4>
                  <p>
                    <a href="#!" class="text-reset">HBG overview</a>
                  </p>
                  <p>
                    <a href="#!" class="text-reset">Project overview</a>
                  </p>
                  <p>
                    <a href="#!" class="text-reset">Token Metric</a>
                  </p>
                  <p>
                    <a href="#!" class="text-reset">Whitepaper</a>
                  </p>
                </div>

                <div class="col-md-3 col-lg-2 col-xl-2 mx-auto mb-2">
                  <h4 class="text-uppercase fw-bold mb-4 text-footer">MENU</h4>
                  <p>
                    <a href="#" class="text-reset">Home</a>
                  </p>
                  <p>
                    <a href="#about" class="text-reset">About</a>
                  </p>
                  <p>
                    <a href="#base" class="text-reset">Base</a>
                  </p>
                  <p>
                    <a href="#benefit" class="text-reset">Benefit</a>
                  </p>
                  <p>
                    <a href="#token" class="text-reset">Tokenomoic</a>
                  </p>
                  <p>
                    <a href="#roadmap" class="text-reset">Roadmap</a>
                  </p>
                  <p>
                    <a href="#partner" class="text-reset">Partner</a>
                  </p>
                  <p>
                    <a href="#community" class="text-reset">Community</a>
                  </p>
                </div>

                <div class="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                  <h4 class="text-uppercase fw-bold mb-4 text-footer">INFO</h4>
                  <p><a href="#!" class="text-reset">Official Token</a></p>
                  <p>
                    <a href="#!" class="text-reset">Help Center</a>
                  </p>
                  <p>
                    <a href="#!" class="text-reset"
                      ><img
                        src="~@/assets/images/Artboard 64.png"
                        alt="buyHBG"
                        class="img-fluid animate-btn"
                        style="width: 200px"
                    /></a>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <!-- Section: Links  -->
        </footer>
      </div>
    </div>
    <!-- Modal-video -->
    <b-modal id="modal-center" centered title="BootstrapVue" size="xl" hide-footer hide-header>
      <div class="youtube-section">
        <div id="player" />
      </div>
    </b-modal>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel';
import ICountUp from 'vue-countup-v2';
import next from '@/assets/images/next.png';
import prev from '@/assets/images/prev.png';

export default {
  components: { Carousel, Slide, ICountUp },
  data() {
    return {
      player: null,
      nextLabel: "<img src='~@/assets/images/next.png' />",
      prevLabel: "<img src='~@/assets/images/prev. png' />",
      landownerbenefits: [
        { img: '1', content: 'Sustainable and reputable ecosystem with Herobook and Herocard' },
        {
          img: '2',
          content:
            'Potential to increase the price of virtual real estate (x multiple times value)',
        },
        {
          img: '3',
          content:
            'The platform has a large number of players the opportunity to make money from advertising',
        },
        { img: '4', content: 'Passive income based on farming, mining and owning' },
        { img: '5', content: 'Build your own virtual business' },
        {
          img: '6',
          content:
            'Become the leading Metaverse and provide a platform for people to truly interact with each other',
        },
        {
          img: '7',
          content:
            'Increased ability to receive taxes from other players to create a passive income stream when owning plots near mineral mines',
        },
      ],
      series: [
        30000000, 150000000, 50000000, 150000000, 250000000, 150000000, 120000000, 100000000,
      ],
      chartOptions: {
        chart: {
          width: 372,
          type: 'pie',
        },
        legend: {
          show: false,
        },
        colors: [
          '#ffffff',
          '#00ff60',
          '#7dd2cb',
          '#c30000',
          '#d56969',
          '#acdc00',
          '#849040',
          '#586198',
        ],
        stroke: {
          colors: [],
          width: 1,
        },
        tooltip: {
          y: {
            formatter(val) {
              // eslint-disable-next-line global-require
              const numeral = require('numeral');
              // eslint-disable-next-line no-restricted-properties
              const calcDec = Math.pow(10, 0);
              const newNum = Math.trunc(val * calcDec) / calcDec;
              const d = newNum.toString().split('.');
              return `${numeral(d[0]).format(0, 0)}${d[1] ? `.${d[1]}` : ''} Token`;
            },
            title: {
              formatter(seriesName) {
                return '';
              },
            },
          },
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: ['#fff'],
          },
          formatter(val, opt) {
            // return `(${opt.seriesIndex + 1})`;
          },
          offsetX: 0,
          dropShadow: {
            enabled: true,
          },
        },
        labels: [
          'Airdrop campaign and marketing promotion',
          'Pre-Sale Release',
          'under the management of the Advisory Board',
          'listed on reserve fund and exchange',
          'for P2E - accompanying ecosystem',
          'under the management of the development team and ecosystem administrator',
          'for international marketing and expansion',
          'for project development fund',
        ],
        // responsive: [
        //   {
        //     breakpoint: 480,
        //     options: {
        //       chart: {
        //         width: 300,
        //       },
        //     },
        //   },
        // ],
      },
      widthRs: 0,
      delay: 2000,
      delay2: 2000,
      options2: {
        useEasing: true,
        useGrouping: true,
        separator: '.',
        decimal: '.',
        prefix: '',
        suffix: '',
        decimalPlaces: 0,
      },
      options3: {
        useEasing: true,
        useGrouping: true,
        separator: ',',
        decimal: '.',
        prefix: '',
        suffix: ' Tokens',
        decimalPlaces: 0,
      },
      ourPartners: [
        {
          img: '1',
          link: '',
        },
        {
          img: '2',
          link: '',
        },
        {
          img: '3',
          link: '',
        },
        {
          img: '4',
          link: '',
        },
        {
          img: '5',
          link: 'http://wiki.hash.kr/index.php/히어로북토큰',
        },
        {
          img: '6',
          link: 'https://chunjo.net',
        },
        {
          img: '7',
          link: 'https://coin88.vn/',
        },
        {
          img: '8',
          link: 'http://dccapital.tech',
        },
        {
          img: '9',
          link: '',
        },
        {
          img: '10',
          link: '',
        },
        {
          img: '11',
          link: 'http://Cashbackpro.net',
        },
      ],
      mediaPartners: [
        { img: '1', link: 'https://coinmarketcap.com/currencies/herobook/' },
        { img: '2', link: 'https://bscscan.com/' },
        { img: '3', link: 'https://airdropachiever.in/' },
        { img: '4', link: 'https://twitter.com/cryptotownEU' },
        {
          img: '5',
          link: 'https://cryptodaily.co.uk/2022/01/herobook-inherits-the-quintessence-of-the-traditional-game',
        },
        { img: '6', link: 'https://t.me/AirdropFather/440' },
        { img: '7', link: 'https://t.me/airdrop6community/421227' },
        { img: '8', link: 'https://t.me/AirdropStar/3159' },
        {
          img: '9',
          link: 'https://newsbsc.com/cryptocurrency/hero-book-conquering-the-world-of-nft-games-with-a-novel-concept/',
        },
        {
          img: '10',
          link: 'https://www.certik.com/projects/herobook',
        },
        {
          img: '11',
          link: 'https://www.team.finance/view-coin/0x8c2Da84EA88151109478846cc7c6c06C481dbe97',
        },
        {
          img: '12',
          link: '',
        },
        {
          img: '13',
          link: 'https://t.me/CryptoPowerAnn/985',
        },
        {
          img: '14',
          link: 'https://link.medium.com/oSytCeSIhob',
        },
        {
          img: '15',
          link: 'https://bcblueskyventures.com/ama-recap-bc-blue-sky-ventures-herobook/',
        },
        {
          img: '16',
          link: 'https://coinness.live/news/1025827',
        },
        {
          img: '17',
          link: 'http://www.blockchaintoday.co.kr/news/articleView.html?idxno=21535',
        },
        {
          img: '18',
          link: '',
        },
        {
          img: '19',
          link: '',
        },
      ],
      backers: ['#', '#', '#', '#', '#', '#'],
      newsPapers: [
        {
          img: '1',
          link: 'https://tiendientu.com/herobook-va-su-xuat-hien-lam-gon-song-thi-truong-game-metaverse',
        },
        {
          img: '2',
          link: 'https://doanhnhanduongthoi.com.vn/dong-hanh-cung-cong-nghe-trong-nuoc-a10452.html',
        },
        {
          img: '3',
          link: 'https://vietnamindex.vn/sau-airdrop-whitelist-la-co-hoi-cuoi-cung-de-buoc-vao-the-gioi-metaverse-tai-herobook-voi-muc-gia-0-dong-a121213.html?zarsrc=30&utm_source=zalo&utm_medium=zalo&utm_campaign=zalo',
        },
        {
          img: '4',
          link: 'https://thanhnien.vn/lua-chon-dong-hanh-cung-startup-cong-nghe-viet-tiem-nang-vuon-ra-the-gioi-post1439905.html',
        },
        {
          img: '5',
          link: 'https://www.doisongphapluat.com/pi-solution-startup-cong-nghe-viet-nam-di-dau-ve-loi-the-canh-tranh-tai-khu-vuc-a531868.html',
        },
        {
          img: '6',
          link: 'https://tctd.vn/trong-ky-nguyen-cua-nft-ino-lieu-co-lua-chon-thong-minh-de-toi-uu-loi-nhuan-a3021.html',
        },
        {
          img: '7',
          link: 'https://doisongdansinh.vn/da-den-luc-tai-dinh-hinh-lai-lai-cac-van-de-cua-thi-truong-game-a1027',
        },
      ],
      base: [
        {
          img: 5,
          content:
            'Like creating a true kingdom, you need a core command center to lay the foundation for your future empire building. Well, the Command Center is the first real estate you must have when arriving at Heroland.<br /> Why is the Command Center the "starter" of an empire?<br />● As a symbol of power: Mark territory and hold armies<br />● As a premise to build other infrastructures<br />● As the "factory" that creates Genesis Seed with Heronium and Herostone.<br />Materials: Heronium',
          title: 'Command Center',
        },
        {
          img: 4,
          content:
            'The Herostone Station was a key piece in the financial picture of the Heroland empire. All growth will freeze without Herostone - the main ingredient that makes up the Genesis Seed.<br /> The Herostone mines will be mined by solar drones dispatched from Herostone mining stations built and powered by electricity. That is why a Heroland Station is only built after you already own an Electric Base on Heroland. Material: Oxygen - Electric - Heronium ',
          title: 'Oxygen Base',
        },
        {
          img: 3,
          content:
            "The development of a civilization can not exist without electric power. Not only survival, but we also need by all means to promote civilization in Heroland. That's why you need an Electric Base<br /> With the mechanism of using Oxygen to create fusion reactions on the Martian surface, the Electric Base:<br /> ● Producing electricity to maintain the city's operation <br />● Operating unmanned aerial vehicles (UAV) to serve the mining industry <br /> Materials: Oxygen'",
          title: 'Electric Base',
        },
        {
          img: 2,
          content:
            'Like creating a true kingdom, you need a core command center to lay the foundation for your future empire building. Well, the Command Center is the first real estate you must have when arriving at Heroland.<br /> Why is the Command Center the "starter" of an empire?<br /> ● As a symbol of power: Mark territory and hold armies <br />● As a premise to build other infrastructures <br />● As the "factory" that creates Genesis Seed with Heronium and Herostone.<br /> Materials: Heronium ',
          title: 'Heronium Station',
        },
        {
          img: 1,
          content:
            'The Herostone Station was a key piece in the financial picture of the Heroland empire. All growth will freeze without Herostone - the main ingredient that makes up the Genesis Seed.<br />The Herostone mines will be mined by solar drones dispatched from Herostone mining stations built and powered by electricity. That is why a Heroland Station is only built after you already own an Electric Base on Heroland. Material: Oxygen - Electric - Heronium',
          title: 'Herostone Station',
        },
      ],
      itemBase: {
        title: 'Herostone Station',
        img: 1,
        content:
          'The Herostone Station was a key piece in the financial picture of the Heroland empire. All growth will freeze without Herostone - the main ingredient that makes up the Genesis Seed.<br />The Herostone mines will be mined by solar drones dispatched from Herostone mining stations built and powered by electricity. That is why a Heroland Station is only built after you already own an Electric Base on Heroland. Material: Oxygen - Electric - Heronium',
      },
    };
  },
  mounted() {
    this.widthRs = window.innerWidth;
  },
  computed: {
    navigationNext() {
      const chevronRight = next;
      return `<img src="${chevronRight}"/>`;
    },
    navigationPrev() {
      const chevronLeft = prev;
      return `<img src="${chevronLeft}"/>`;
    },
  },
  methods: {
    onChooseBase(i) {
      this.itemBase = this.base[i];
      console.log(this.itemBase);
    },
    loadVideo() {
      function onPlayerReady(event) {
        event.target.playVideo();
      }
      // eslint-disable-next-line no-unused-vars
      let player = null;

      function setupPlayer() {
        window.YT.ready(() => {
          player = new window.YT.Player('player', {
            width: '100%',
            height: '100%',
            videoId: 'a5halkyhLfw',
            events: {
              onReady: onPlayerReady,
            },
            playerVars: {
              playsinline: 1,
              controls: 0,
              disablekb: 1,
              fs: 0,
              iv_load_policy: 3,
              rel: 0,
              showinfo: 1,
              wmode: 'opaque',
              origin: 'http://localhost:8080',
            },
          });
        });
      }

      (function loadYoutubeIFrameApiScript() {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';

        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        tag.onload = setupPlayer;
      }());
    },
    handleResize() {
      this.widthRs = window.innerWidth;
    },
  },
  async created() {
    window.addEventListener('resize', this.handleResize);
  },
  destroyed() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="scss">
.padding-custom {
  padding-top: 142px;
  padding-bottom: 3em;
  @media (max-width: 575px) {
    padding-top: 40px;
    padding-bottom: 2em;
  }
}
.home {
  font-family: 'Space Mono', monospace;
  .position-relative {
    position: relative;
    z-index: 10;
  }
  .animate-btn {
    transition: 0.5s;
  }

  .animate-btn:hover {
    transform: scale(1.1);
    cursor: pointer;
  }
  #banner {
    .container-fluid {
      padding-left: 0;
      padding-right: 0;
      position: relative;
      z-index: 10;
    }

    .animate-logo {
      animation: tokencoin 1.8s linear infinite;
      transition: 0.5s;
    }

    .animate-logo:hover {
      transform: scale(1.1);
    }
    .text-heading {
      color: #00ff00;
      text-shadow: 0px 0px 5px #00ff00;
    }
    .bg-image {
      width: 100%;
      height: auto;
      background-image: url('~@/assets/images/Header/Artboard-2.1.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }

    .banner-content {
      font-size: 4rem;
      font-weight: bolder;
      // background-color: rgba(5, 5, 5, 0.633);
      width: auto;
      text-align: center;
      line-height: 100%;
      padding: 0px 0px 10px 0px;

      @media (max-width: 991px) {
        padding: 0px 10px 10px 10px;
        font-size: 3.5rem;
      }
      @media (max-width: 767px) {
        font-size: 3rem;
      }
      @media (max-width: 767px) {
        font-size: 25px;
      }
      .banner-content2 {
        background-color: rgba(5, 5, 5, 0.633);
        border: 2px solid #601203;
        width: 700px;
        height: 100%;
        min-height: 70px;
        margin: 0 auto;
        @media (max-width: 991px) {
          min-height: 80px;
        }
        @media (max-width: 767px) {
          width: 500px;
          min-height: 60px;
        }
        @media (max-width: 575px) {
          width: 100%;
          min-height: 60px;
        }
        .typing {
          font-size: 3rem;
          color: #00ff00;
          text-shadow: 0px 0px 5px #00ff00;
          font-weight: bolder;
          @media (max-width: 991px) {
            font-size: 3rem;
          }
          @media (max-width: 767px) {
            font-size: 25px;
            margin-bottom: 0px;
          }
          @media (max-width: 375px) {
            font-size: 20px;
          }
        }
        .typed-cursor {
          font-size: 3rem;
          color: #00ff00;
          background: none !important;
          text-shadow: 0px 0px 5px #00ff00;
          font-weight: bolder;
          padding: 0px;
          margin-top: -30px;
          @media (max-width: 767px) {
            font-size: 25px;
            margin-top: 0px;
          }
          @media (max-width: 375px) {
            font-size: 20px;
          }
        }
      }
    }

    .trailer-btn {
      color: rgb(137, 248, 137);
      font-size: 1.2rem;
      font-weight: bold;
      background-color: rgba(255, 0, 0, 0.204);
      padding: 0.7rem 3rem;
      text-align: center;
      transition: 0.5s;
      line-height: normal;
      border: 2px solid #00ff00;
    }

    .trailer-btn:hover {
      transform: scale(1.1);
      cursor: pointer;
    }

    .banner-btn {
      width: 350px;
      height: auto;
      .ml-custom-button-banner {
        margin-left: 100px;
        @media (max-width: 1699px) {
          margin-left: 50px;
        }
        @media (max-width: 1399px) {
          margin-left: 0px;
        }
      }
      .mr-custom-button-banner {
        margin-right: 100px;
        @media (max-width: 1699px) {
          margin-right: 50px;
        }
        @media (max-width: 1399px) {
          margin-right: 0px;
        }
      }
    }
    .footer-banner {
      background-image: url('~@/assets/images/Header/Artboard-35_1.png');
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      height: 100%;
      min-height: 200px;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      @media (max-width: 575px) {
        flex-wrap: wrap;
      }
      .item {
        .item-number {
          color: #00ff00;
          text-shadow: 0px 0px 5px #00ff00;
          font-size: 50px;
          font-weight: bold;
          @media (max-width: 767px) {
            font-size: 35px;
          }
        }
        .item-text {
          color: #ffffff;
          text-shadow: -1px -1px 20px #00ff00;
          font-weight: 600;
          background-color: #601203;
          line-height: 100%;
          padding-bottom: 10px;
          font-size: 20px;
          word-spacing: 10px;
          @media (max-width: 767px) {
            font-size: 16px;
            word-spacing: 5px;
          }
        }
      }
    }
  }
  #about {
    &.bg-image {
      width: 100%;
      height: auto;
      background-image: url('~@/assets/images/Artboard-32.jpg');
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      .container {
        position: relative;
        z-index: 10;
      }
      p {
        font-size: 22px;
      }
    }
  }
  #content {
    background-image: url('~@/assets/images/Artboard-24.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    .bg-image-heroland-content {
      .heroland-base {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .bg-content-typing {
          background-color: #00ff00;
          width: 500px;
          height: 100%;
          min-height: 70px;
          position: relative;
          z-index: 2;
          align-items: center;
          @media (max-width: 575px) {
            width: 100%;
            min-height: 50px;
          }
          .typed-element {
            @media (max-width: 575px) {
              width: 100%;
              justify-content: center;
            }
            .typing {
              color: #000000;
              text-shadow: -1px -1px 20px #00ff00;
              font-weight: 700;
              font-size: 50px;
              width: fit-content;
              line-height: 100%;
              margin-bottom: 0px;
              @media (max-width: 575px) {
                font-size: 40px;
              }
              @media (max-width: 414px) {
                font-size: 30px;
              }
              @media (max-width: 375px) {
                font-size: 25px;
              }
            }
            .typed-cursor {
              font-size: 50px;
              color: #000000;
              background: none !important;
              text-shadow: -1px -1px 20px #00ff00;
              font-weight: 700;
              padding: 0px;
              margin-top: 0px;
              @media (max-width: 575px) {
                font-size: 40px;
              }
              @media (max-width: 414px) {
                font-size: 30px;
              }
              @media (max-width: 375px) {
                font-size: 25px;
              }
            }
          }
        }
        .heroland-base-carousel {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background-image: url('~@/assets/images/Artboard-82.png');
          background-repeat: no-repeat;
          background-position: center;
          background-size: cover;
          height: 300px;
          margin-top: -20px;
          .VueCarousel {
            width: 1200px;
            z-index: 10;
            @media (max-width: 1400px) {
              width: 1000px;
            }
            @media (max-width: 1200px) {
              width: 800px;
            }
            @media (max-width: 991px) {
              width: 500px;
            }
            @media (max-width: 575px) {
              width: 300px;
            }
            @media (max-width: 375px) {
              width: 250px;
            }
            .VueCarousel-wrapper {
              .VueCarousel-inner {
                .VueCarousel-slide {
                  .box-img {
                    text-align: center;
                  }
                }
              }
            }
            .VueCarousel-navigation-button {
              outline: none;
              img {
                @media (max-width: 575px) {
                  width: 30px;
                }
              }
            }
          }
        }
      }
      .electric-base {
        position: relative;
        z-index: 10;
        .img-electric-base {
          width: 500px;
          height: auto;
          @media (max-width: 1199px) {
            width: 400px;
          }
          @media (max-width: 991px) {
            width: 450px;
          }
        }
        .electric-base-content {
          font-size: 22px;
        }
        .base-title {
          width: fit-content;
          height: 70px;
          font-size: 50px;
          font-weight: bold;
          background-color: #000000;
          color: #ffffff;
          text-shadow: -1px -1px 20px #00ff00;
          line-height: 100%;
        }
      }
      .bg-content-typing {
        background-color: #00ff00;
        width: 600px;
        height: 100%;
        min-height: 70px;
        position: relative;
        z-index: 2;
        align-items: center;
        margin: 0 auto;
        margin-bottom: 100px;
        @media (max-width: 575px) {
          width: 100%;
          min-height: 50px;
          margin-bottom: 30px;
        }
        .typed-element {
          @media (max-width: 575px) {
            width: 100%;
            justify-content: center;
          }
          .typing {
            color: #000000;
            text-shadow: -1px -1px 20px #00ff00;
            font-weight: 700;
            font-size: 50px;
            width: fit-content;
            line-height: 100%;
            margin-bottom: 0px;
            @media (max-width: 575px) {
              font-size: 35px;
            }
            @media (max-width: 414px) {
              font-size: 30px;
            }
            @media (max-width: 375px) {
              font-size: 25px;
            }
          }
          .typed-cursor {
            font-size: 50px;
            color: #000000;
            background: none !important;
            text-shadow: -1px -1px 20px #00ff00;
            font-weight: 700;
            padding: 0px;
            margin-top: 0px;
            @media (max-width: 575px) {
              font-size: 35px;
            }
            @media (max-width: 414px) {
              font-size: 30px;
            }
            @media (max-width: 375px) {
              font-size: 25px;
            }
          }
        }
      }
      #benefit {
        position: relative;
        z-index: 10;
      }
    }
    .bg-image-heroland-content2 {
      p {
        font-size: 22px;
        letter-spacing: 2px;
      }
      .bg-color {
        background-color: #00000092;
        .landownerbenefit-content {
          background-image: url('~@/assets/images/Artboard 25.png');
          background-repeat: no-repeat;
          background-position: center;
          background-size: 100% 100%;
          padding: 50px 100px 20px 100px;
          width: 1000px;
          margin: 0 auto;
          top: -50px;
          position: relative;
          z-index: 50;
          @media (max-width: 1099px) {
            width: 900px;
          }
          @media (max-width: 991px) {
            width: 700px;
            top: 0px;
          }
          @media (max-width: 767px) {
            width: 500px;
          }
          @media (max-width: 575px) {
            width: 100%;
            padding: 20px 15px 20px 15px;
          }
          .landownerbenefit-item {
            width: 100%;
            display: flex;
            @media (max-width: 991px) {
              flex-direction: column;
              justify-content: center;
              align-items: center;
            }
            .landownerbenefit-img {
              width: 300px;
              height: 300px;
              margin-right: 50px;
              background-image: url('~@/assets/images/icons-benefits/bg-icon.png');
              background-repeat: no-repeat;
              background-position: center;
              background-size: 100% 100%;
              display: flex;
              justify-content: center;
              align-items: center;
              @media (max-width: 991px) {
                margin-right: 0px;
              }
              @media (max-width: 767px) {
                width: 250px;
                height: 250px;
              }
              img {
                width: 200px;
                height: auto;
                filter: drop-shadow(0 0 0.75rem #34f834);
                @media (max-width: 767px) {
                  width: 150px;
                }
              }
            }
            .landownerbenefit-text {
              width: calc(100% - 350px);
              padding-top: 20px;
              font-size: 30px;
              @media (max-width: 991px) {
                width: 100%;
                text-align: center;
              }
              @media (max-width: 767px) {
                font-size: 20px;
              }
              @media (max-width: 575px) {
                max-width: 300px;
                text-align: center;
              }
            }
          }
          .VueCarousel-pagination {
            .VueCarousel-dot {
              border-radius: unset !important;
              background-color: #00ff0041 !important;
              outline: none !important;
              &.VueCarousel-dot--active {
                background-color: #00ff00 !important;
              }
            }
          }
        }
        .button-group {
          img {
            margin: 0px 15px;
            width: auto;
            height: 80px;
            @media (max-width: 480px) {
              height: 55px;
              margin: 0px 10px;
            }
          }
        }
        #token {
          position: relative;
          z-index: 10;
        }
      }
      .bg-chart {
        background-image: url('~@/assets/images/Artboard-82.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        .percent {
          padding-left: 40px;
          @media (max-width: 991px) {
            padding-left: 0px;
          }
          h2 {
            font-weight: bold;
          }
        }
        .text {
          p {
            font-size: 18px !important;
            letter-spacing: 0px;
          }
        }
        .percent1 {
          color: #ffffff;
        }
        .percent2 {
          color: #00ff60;
        }
        .percent3 {
          color: #7dd2cb;
        }
        .percent4 {
          color: #c30000;
        }
        .percent5 {
          color: #d56969;
        }
        .percent6 {
          color: #acdc00;
        }
        .percent7 {
          color: #849040;
        }
        .percent8 {
          color: #586198;
        }
      }
    }
    .bg-image-heroland-content3 {
      padding: 100px 0px;
      .bg-roadmap {
        background-image: url('~@/assets/images/Artboard 41.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        padding: 50px 0px;
      }
      .bg-content-typing {
        background-color: #00ff00;
        width: fit-content;
        height: 100%;
        position: relative;
        z-index: 2;
        align-items: center;
        padding: 0px 5px 10px 5px;
        margin: 0 auto;
        h1 {
          color: #000000;
          text-shadow: -1px -1px 20px #00ff00;
          font-weight: 700;
          font-size: 50px;
          width: fit-content;
          line-height: 100%;
          margin-bottom: 0px;
          @media (max-width: 575px) {
            font-size: 40px;
          }
          @media (max-width: 414px) {
            font-size: 35px;
          }
        }
      }
    }
    .bg-image-heroland-content4 {
      @media (max-width: 991px) {
        background-size: cover;
      }
      .bg-content-typing {
        background-color: #00ff00;
        width: fit-content;
        height: 100%;
        position: relative;
        z-index: 2;
        align-items: center;
        padding: 0px 5px 10px 5px;
        margin: 0 auto;
        h1 {
          color: #000000;
          text-shadow: -1px -1px 20px #00ff00;
          font-weight: 700;
          font-size: 50px;
          width: fit-content;
          line-height: 100%;
          margin-bottom: 0px;
          @media (max-width: 575px) {
            font-size: 40px;
          }
          @media (max-width: 414px) {
            font-size: 35px;
          }
        }
      }
      .ourpartner {
        margin: 150px 0px 100px 0px;
        @media (max-width: 991px) {
          margin: 50px 0px 50px 0px;
        }
      }
      .bg-color {
        background-color: #00000092;
        &.build {
          padding: 150px 0px;
          @media (max-width: 991px) {
            padding: 50px 0px 50px 0px;
          }
        }
        .text-footer {
          color: #00ff00;
          font-size: 30px;
        }
      }
      .bg {
        background-color: #ffffff98;
        .contact-content {
          h5 {
            background-color: #000000;
            color: #ffffff;
            width: fit-content;
            padding: 2px 5px;
          }
          h3 {
            background-color: #000000;
            color: #ffffff;
            width: fit-content;
            padding: 2px 5px;
            font-weight: bold;
          }
        }
        a {
          p {
            color: #000000;
            font-weight: bold;
          }
        }
      }
      .partner {
        a {
          display: block;
          width: 100%;
          height: 100%;
          background-color: #ffffff;
          padding: 20px;
          border-radius: 15px;
          margin-top: 10px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
}
#modal-center {
  .modal-body {
    height: 500px;
    padding: 0px;
    .youtube-section {
      height: 100%;
    }
  }
}
@keyframes tokencoin {
  25% {
    transform: translateY(-5%) rotateY(0);
  }
  50% {
    transform: translateY(-5%) rotateY(90deg);
  }
  75% {
    transform: translateY(-5%) rotateY(0);
  }
}
</style>
